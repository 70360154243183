import Rest from '../Rest'

export default class ProductService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/products'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  deleteFile(parentId, mediaId) {
    return this.delete(`/${parentId}/files/${mediaId}`).then(
      (response) => {
        return response
      }
    )
  }

  getItems(productId: Number) {
    return this.get(`/${productId}/items`).then(data => {
      return data
    })
  }

  getProductItemTypes() {
    return this.get('/items/types').then(data => {
      return data
    })
  }

  addProductItem(productId: Number, data: any) {
    return this.post(`${productId}/items`, data).then(
      (data) => {
        return data
      }
    )
  }

  deleteProductItem(productId, productItemId) {
    return this.delete(`/${productId}/items/${productItemId}`).then(
      (response) => {
        return response
      }
    )
  }

  getProductToSalesGrid(path: string = '', data: any) {
    return this.grid('/sales/grid', data)
  }

  getProductToSalesCorporateGrid(path: string = '', data: any) {
    return this.grid('/sales/corporate/grid', data)
  }

}
