<template>
  <infinite-grid
    :style="infiniteGridStyleInjection"
    order-column="name"
    direction="asc"
    class="infinite-grid"
    :method="this.corporate_sale ? `getProductToSalesCorporateGrid`: `getProductToSalesGrid`"
    ref="grid"
    :extraParams="extraParam"
    :service="service">
    <template v-slot:item="data">
      <product-card :item="data.item"
        :corporate_sale="corporate_sale"
        @updateCartHandling="updateCartHandling"
        :validatedExistProductInCart="existItemCart"/>

    </template>
  </infinite-grid>
</template>

<script>

import { currencyFormatter } from '@/util/Util'

import infiniteGrid from '@/components/infiniteGrid.vue'
import ProductService from '@/services/api/ProductService'

import ProductCard from './ProductCard'

export default {
  components: {
    infiniteGrid,
    ProductCard
  },
  props: {
    corporate_sale: {
      default: false,
      type: Boolean
    },
    encrypParam: {
      default: null,
      type: String
    },
    validatedExistProductAdd: {
      type: Function,
      default: null
    }
  },
  data: () => ({
    columns: null,
    classCount: 0,
    qttTotal: null,
    service: null,

  }),
  computed: {
    infiniteGridStyleInjection() {
      return `--infinite-grid-cols: ${this.columns || this.gridCols()};`
    },
    extraParam(){
      if(this.encrypParam){
        return {'encryp_param':this.encrypParam}
      }
      return null
    }
  },
  methods: {
    quantityChange(event, data) {
      let qtt = event.target.value
      try{
          Number.parseInt(qtt)
      }catch(err){
        event.target.value = 0
      }
      //
      if (qtt && Number.parseInt(qtt) < 0) {
        event.target.value = 0
      }

      const itemValue = Number.parseFloat(data.value)
      qtt =  Number.parseInt(event.target.value)
      const total = qtt*itemValue
      this.qttTotal = total

      console.log('qtt changing...:'+event.target.value)
    },
    formatValue(value){
      return currencyFormatter(value)
    },
    gridCols() {
      const colLimit = this.$utils.reactive.breakpointColsMax()
      const colMinimum = this.$utils.reactive.breakpointColsMin()
      return this.$utils.reactive.colsForTotal(this.classCount, colLimit, colMinimum)
    },
    recomputeGridCols() {
      this.columns = this.gridCols()
    },
    updateCartHandling(data){
      this.$emit('updateCartHandling', data)
    },
    existItemCart(data){
      if(this.validatedExistProductAdd){
        return this.validatedExistProductAdd(data)
      }
      return false
    }
  },
  mounted(){

  },
  beforeMount() {
    this.service = ProductService.build(this.$vs)
    window.addEventListener('resize', this.recomputeGridCols)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.recomputeGridCols)
  }
}
</script>

<style>
  ._class-card-title, ._class-card-description {
    @apply overflow-hidden;
    @apply text-justify;
    @apply break-words;
  }
  ._class-card-title {
    min-height: 1.5rem;
    max-height: 1.5rem;
  }
  ._class-card-description {
    min-height: 5rem;
    max-height: 5rem;
  }
</style>

<style lang="scss" scoped>
.infinite-grid /deep/ ._infinite-grid {
  grid-template-columns: repeat(var(--infinite-grid-cols), minmax(0, 1fr));
}
</style>
