var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vs-popup",
    {
      ref: "vspopup-per",
      attrs: { title: _vm.item.name, active: _vm.visible, id: "popup-entity" },
      on: {
        "update:active": function ($event) {
          _vm.visible = $event
        },
        close: _vm.closePopup,
      },
    },
    [
      _c(
        "vs-tabs",
        { attrs: { color: _vm.colorx } },
        [
          _c("vs-tab", { attrs: { label: _vm.$t("product.overview") } }, [
            _c("div", {
              directives: [
                {
                  name: "html-safe",
                  rawName: "v-html-safe",
                  value: _vm.item.description,
                  expression: "item.description",
                },
              ],
              staticClass: "mb-2",
            }),
            _c("div", { staticClass: "mt-2 w-full" }, [
              _vm.item.files && _vm.item.files.length > 0
                ? _c(
                    "fieldset",
                    { staticClass: "mt-4 border-faint-grey" },
                    [
                      _c("legend", [_vm._v(_vm._s(_vm.$t("attachments")))]),
                      _c("MediaList", {
                        attrs: {
                          medias: _vm.item.files,
                          "show-delete": false,
                          "action-on-click": false,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
          _c("vs-tab", { attrs: { label: _vm.$t("product.how_works") } }, [
            _c("div", {
              directives: [
                {
                  name: "html-safe",
                  rawName: "v-html-safe",
                  value: _vm.item.how_works,
                  expression: "item.how_works",
                },
              ],
              staticClass: "mb-2",
            }),
          ]),
          _c("vs-tab", { attrs: { label: _vm.$t("product.guidelines") } }, [
            _c("div", {
              directives: [
                {
                  name: "html-safe",
                  rawName: "v-html-safe",
                  value: _vm.item.guidelines,
                  expression: "item.guidelines",
                },
              ],
              staticClass: "mb-2",
            }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }