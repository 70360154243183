var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "cart-resume-side-bar" } },
    [
      !_vm.active
        ? _c("vs-button", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip.bottom",
                value: {
                  content: _vm.$t("marketing_place.purchase_summary"),
                  delay: { show: 100, hide: 600 },
                },
                expression:
                  "{\n            content: $t('marketing_place.purchase_summary'),\n            delay: { show: 100, hide: 600 },\n          }",
                modifiers: { bottom: true },
              },
            ],
            staticClass: "customizer-btn",
            attrs: {
              color: "primary",
              type: "filled",
              "icon-pack": "feather",
              icon: "icon-check-square",
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                _vm.active = !_vm.active
              },
            },
          })
        : _vm._e(),
      _c(
        "vs-sidebar",
        {
          staticClass: "items-no-padding",
          attrs: {
            "click-not-close": "",
            "hidden-background": "",
            "position-right": "",
          },
          model: {
            value: _vm.active,
            callback: function ($$v) {
              _vm.active = $$v
            },
            expression: "active",
          },
        },
        [
          _c("CartResume", {
            attrs: {
              cartItems: _vm.cartItems,
              isSideBar: true,
              removeItemEnable: true,
            },
            on: {
              updateCartHandling: _vm.updateCartHandling,
              closeSideBar: _vm.closeSideBar,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }