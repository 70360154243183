<template>
  <div id="cart-resume-side-bar">
    <!-- Open Customizer Button -->
    <vs-button v-if="!active"
      @click.stop="active=!active"
      color="primary"
      type="filled"
      class="customizer-btn"
      icon-pack="feather"
      icon="icon-check-square"
      v-tooltip.bottom="{
              content: $t('marketing_place.purchase_summary'),
              delay: { show: 100, hide: 600 },
            }">
    </vs-button>

    <!-- Customizer Content -->
    <vs-sidebar
      click-not-close
      hidden-background
      position-right
      v-model="active"
      class="items-no-padding">

        <CartResume :cartItems="cartItems" @updateCartHandling="updateCartHandling" :isSideBar="true" :removeItemEnable="true" @closeSideBar="closeSideBar"/>

    </vs-sidebar>
  </div>
</template>

<script>

import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import CartResume from './CartResume'


export default {
  components: {
    VuePerfectScrollbar,
    CartResume
  },
  props: {
    cartItems: {type: Array, default: () => [], required: true},
  },
  data () {
    return {
      active: false,
    }
  },
  watch: {

  },
  computed: {
    hideScrollToTopLocal: {
      get ()    { return this.hideScrollToTop },
      set (val) { this.$emit('toggleHideScrollToTop', val) }
    },
    scrollbarTag () { return this.$store.state.is_touch_device ? 'div' : 'VuePerfectScrollbar' },
    windowWidth () {
      return this.$store.state.windowWidth
    }
  },
  methods: {
    closeSideBar(){
      this.active = false
    },
    updateCartHandling(item){
      this.$emit('updateCartHandling', item)
    },
  },
  beforeMount() {

  },
}

</script>


<style lang="scss">
#cart-resume-side-bar {
  .vs-sidebar {
    position: fixed;
    z-index: 52000;
    width: 400px;
    max-width: 90vw;
    // @apply shadow-lg;
    box-shadow: 0 15px 30px 0 rgba(0,0,0,0.11), 0 5px 15px 0 rgba(0,0,0,0.08);
  }
}

.customizer-btn {
  position: fixed;
  top: 30%;
  right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  z-index: 50000;

  .vs-icon {
    animation: 1.5s ease-in-out infinite;
  }
}

.scroll-area--customizer {
  height: calc(100% - 5rem);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>

