<template>
    <vs-popup :title="item.name" :active.sync="visible" id="popup-entity" @close="closePopup" ref="vspopup-per">
      <vs-tabs :color="colorx">
        <vs-tab :label="$t('product.overview')">
          <div class="mb-2" v-html-safe="item.description"></div>
          <div class="mt-2 w-full">
            <fieldset class="mt-4 border-faint-grey" v-if="item.files && item.files.length > 0">
              <legend>{{ $t('attachments') }}</legend>
              <MediaList :medias="item.files"
                        :show-delete="false"
                        :action-on-click="false" />
            </fieldset>
          </div>
        </vs-tab>
        <vs-tab :label="$t('product.how_works')">
          <div class="mb-2" v-html-safe="item.how_works"></div>
        </vs-tab>
        <vs-tab :label="$t('product.guidelines')">
          <div class="mb-2" v-html-safe="item.guidelines"></div>
        </vs-tab>
      </vs-tabs>
    </vs-popup>
</template>

<script>
import { currencyFormatter } from '@/util/Util'

import MediaList from '@/components/media/MediaList.vue'

export default {
  components: {
    MediaList
  },
  props: {
    visible :{
      type: Boolean,
      default: false
    },
    item :{
      type: Object,
      default: null
    }
  },
  data: () => ({

  }),
  computed: {

  },
  methods: {
    formatValue(value){
      return currencyFormatter(value)
    }
  },
  beforeMount() {

  },
  mounted() {

  },
  watch: {
    visible(val){
      if(val){
        //this.applyFiltersGrid()
      }
    }
  },
}
</script>


<style lang="scss" scoped>
  ::v-deep .vs-popup {
    width: 800px !important;
  }
</style>
