import Rest from '../Rest'

export default class MediaService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/medias'

  static build(vs: any = null, baseURL: any = null) {
    return new this(this.resource, null, vs, baseURL)
  }

  upload(id: Number, data: FormData) {
    return this.post(`/${id}/upload`, data, { headers: {
      'Content-Type': 'multipart/form-data;'
    }}).then(data => {
      return data
    })
  }

  uploadPart(data: FormData) {
    return this.post('/upload_part', data, {
      'Content-Type': 'multipart/form-data;'
    }).then(data => {
      return data
    })
  }

  url(mediaId: Number) {
    return this.get(`${mediaId}/url`).then(data => {
      return data
    })
  }

  optimizeImage(data: FormData, id = 0) {
    return this.post(`/${id}/optimize_image`, data, {
      'Content-Type': 'multipart/form-data;'
    }).then(data => {
      return data
    })
  }

  optimizeUrl(id: number, url: string) {
    return this.post(`/${id}/optimize_url`, {
      id: id,
      url: url
    }).then(data => {
      return data
    })
  }

  downloadMedia(id: number, filename: string, mimeType: '') {
    return this.download(`/${id}/download`, filename, mimeType)
  }
}
