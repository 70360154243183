<template>
  <div>
    <vx-card
      class="w-auto shadow-drop border-solid border-grey border playful"
      headerStyle="w-full text-left font-bold text-white pb-4">
      <template v-slot:header>
        <div class="pt-2 flex items-center justify-between">
          <label class="_class-card-title">{{ _.get(item, 'name')}}</label>
          <feather-icon
            v-if="false"
            svgClasses="w-5 h-5 2xl:w-4 2xl:h-4"
            class="cursor-pointer"
            icon="MoreVerticalIcon"/>
        </div>
      </template>
      <div class="flex flex-col w-full gap-3 justify-center">
        <p class="_class-card-description" v-html="_.get(item, 'description')">
        <div class="flex justify-center">
          <vs-button type="border" @click="openDetailItem(item)">{{ $t('action.detail') }}</vs-button>
        </div>
        <div class="flex justify-center">
          <label  class="text-md">
            <span class="font-bold"> {{formatValue(_.get(item, 'price')) }}</span>
          </label>
        </div>
      </div>
      <template v-slot:footer>
        <div class="w-full flex flex-shrink-0 flex-wrap">
          <div class="w-full grid grid-cols-2 gap-2" v-if="corporate_sale">
            <div class="flex">
              <vs-input
                  lang="pt-BR"
                  type="number"
                  v-model="quantity"
                  @change="quantityChange($event)"
                  :label="$t('marketing_place.quantity')"/>
            </div>
            <div class="flex">
              <vs-input
                  readonly
                  v-model="priceTotal"
                  :label="$t('marketing_place.total')"/>
            </div>
          </div>
          <div class="w-full flex gap-2 items-center justify-center pt-4">
              <vs-button
                icon="add"
                v-tooltip="{
                    content: $t('marketing_place.add_to_cart'),
                    delay: { show: 500, hide: 100 }
                  }"
                @click="addItem" class="px-2 py-2">
                <span class="inline pl-1">{{ $t('adicionar') }}</span>
              </vs-button>
          </div>
        </div>
      </template>
    </vx-card>
    <ProductDetailPopup
        :key="keyPopup"
        :item="item"
        :visible="showProductDetailPopup"
        @close="closeProductDetailPopup"/>
  </div>
</template>

<script>

import { currencyFormatter } from '@/util/Util'
import { currencyFormatterWithoutSymbol } from '@/util/Util'

import ProductDetailPopup from './ProductDetailPopup.vue'

import CartService from '@/services/api/CartService'

export default {
  components: {
    ProductDetailPopup
  },
  props: {
    item: {
      default: null,
      type: Object
    },
    corporate_sale: {
      default: false,
      type: Boolean
    },
    validatedExistProductInCart: {
      default: null,
      type: Function
    }
  },
  data: () => ({
    quantity: 1,
    priceTotal: 0,
    showProductDetailPopup: false,
    keyPopup: 0,
    service: null,
  }),
  computed: {

  },
  watch: {
    priceTotal(val) {
      //this.priceTotal = this.formatValue(val)
    }
  },
  methods: {
    addItem() {
      if(!this.isUserLogged()){
        this.notifyLoginRequired()
        return
      }

      if(this.existItemCart(this.item)){
        return
      }

      this.$vs.loading()
      const modelData = {
        product_id: this.item.id,
        corporate_sale: this.corporate_sale,
        quantity: this.quantity,
      }

      this.service.addCartItemInOpenCart({form : modelData}).then(
        data => {
          this.updateCartHandling(data)
          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
        },
        error => {
          this.showErrors(this.$validator, error)
          this.notifyError(
            this.$vs,
            this.$t('nao-foi-possivel-realizar-esta-operacao')
          )
        }
      ).finally(() => { this.$vs.loading.close() })
    },
    quantityChange(event) {
      let quantity = event.target.value
      try{
          Number.parseInt(quantity)
      }catch(err){
        event.target.value = 1
      }

      if (quantity && Number.parseInt(quantity) <= 0) {
        event.target.value = 1
      }

      this.updateQuantityTotal(event.target.value, this.item.price);

      //console.log('qtt changing...:'+event.target.value)
    },
    updateQuantityTotal(qtt, price){
      const itemValue = Number.parseFloat(price)
      const qttotal =  Number.parseInt(qtt)
      const total = qttotal*itemValue
      this.priceTotal = total
    },
    formatValue(value){
      return currencyFormatter(value)
    },
    openDetailItem(){
      this.keyPopup++
      this.showProductDetailPopup = true
    },
    closeProductDetailPopup(show){
      this.showProductDetailPopup = show
    },
    updateCartHandling(data){
      this.$emit('updateCartHandling', data)
    },
    existItemCart(data){
      if(this.validatedExistProductInCart){
        return this.validatedExistProductInCart(data)
      }
      return false
    },

    isUserLogged() {
      return this.$store.state.auth.isUserLoggedIn()
    },
    notifyLoginRequired(){
      this.$vs.notify({
        time: 5000,
        title: this.$t('atencao'),
        text: this.$t('marketing_place.login_required'),
        position: 'top-center',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      })
    },
  },
  mounted(){
    this.updateQuantityTotal(this.quantity, _.get(this.item, 'price'))

  },
  beforeMount() {
    this.service = CartService.build(this.$vs)
  },
  beforeDestroy() {

  }
}
</script>

<style lang="scss" scoped>
  input[type="number"] {
    text-align: center !important;
  }
  input[type="text"] {
    text-align: right !important;
  }
  ._class-card-title, ._class-card-description {
    @apply overflow-hidden;
    @apply text-justify;
    @apply break-words;
  }
  ._class-card-title {
    min-height: 1.5rem;
    max-height: 1.5rem;
  }
  ._class-card-description {
    min-height: 5rem;
    max-height: 5rem;
    /*
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    height:56px;
    */
  }
</style>

<style lang="scss" scoped>

.playful /deep/ .vx-card__header {
  padding-top: 0.5rem;
  //background: padding-box linear-gradient(218deg,#00eef6,#00dde6 50%,#31acff);
  background: padding-box  linear-gradient(218deg, rgba(var(--vs-primary), 1), rgba(var(--vs-primary), .7)) !important;
  border-radius: 0.4rem;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}


.playful /deep/ .vx-card__footer {
  border-top: 1px solid #b8c2cc80;
  padding: 0.25rem 0.50rem 0.50rem 0.50rem;
}

.playful /deep/ .vx-card__body {
  padding: 1rem;
}

</style>
