<template>
  <!-- CART DROPDOWN -->
  <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
      <feather-icon icon="ShoppingCartIcon" class="cursor-pointer ml-4 mr-6 mt-1" :badge="cartItemsLength" />
      <vs-dropdown-menu class="cart-dropdown vx-navbar-dropdown" :class="{'dropdown-custom': cartItems.length}">

          <!-- IF CART HAVE ITEMS: HEADER -->
          <template v-if="cartItems.length">
              <div class="notification-header text-center p-5 bg-primary text-white">
                  <h3 class="text-white">
                      {{ cartItemsLength }}
                      <span v-show="cartItemsLength > 1">{{$t('marketing_place.items')}}</span>
                      <span v-show="cartItemsLength <= 1">{{$t('marketing_place.item')}}</span>
                    </h3>
                  <p class="opacity-75">{{$t('marketing_place.items_in_cart')}}</p>
              </div>

              <!-- CART ITEMS -->
              <component :is="scrollbarTag" ref="mainSidebarPs" class="scroll-area--cart-items-dropdowm p-0 mb-10" :settings="settings" :key="$vs.rtl">
              <ul class="bordered-items">
                  <li
                    v-for="item in cartItems"
                    :key="item.id"
                    class="vx-row no-gutter cart-item cursor-pointer">

                      <!-- IMG COL -->
                      <!--
                      <div class="vx-col w-1/5 item-img-container bg-white flex items-center justify-center">
                          <img :src="item.image" alt="item" class="cart-dropdown-item-img p-4">
                      </div>
                      -->
                      <!-- INFO COL -->
                      <div class="vx-col w-4/5 pr-4 pl-2 py-4 flex flex-col justify-center">
                          <span class="font-medium block cart-item-title truncate pb-4">{{ item.product_name }}</span>
                          <div class="flex items-center justify-between">
                              <span class="text-sm font-small">{{ item.quantity }} <small>x</small>{{formatValue(item.price)}}</span>
                          </div>
                      </div>
                      <div class="vx-col w-1/5 pr-4 pl-2 py-4 flex flex-col justify-start items-center">
                        <feather-icon icon="TrashIcon"
                                      svgClasses="h-4 w-4 cursor-pointer text-danger"
                                      class="hover:text-danger"
                                      @click.stop="removeItemFromCart(item)"
                                      v-tooltip="{
                                        content: $t('marketing_place.remove_from_cart'),
                                        delay: { show: 500, hide: 100 }
                                      }"/>
                      </div>
                  </li>
              </ul>
              </component>
              <div class=" checkout-footer static bottom-0 rounded-b-lg text-primary font-semibold w-full p-2 text-center border border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light cursor-pointer">
                <span class="flex items-center justify-center pt-2 pb-4">
                    {{formatValue(valueTotal)}}
                </span>
                <span class="flex items-center justify-center" @click.stop="paymentPurchase()">
                  <feather-icon icon="ShoppingCartIcon" svgClasses="h-4 w-4" />
                  <span class="ml-2">{{$t('action.payment')}}</span>
                </span>
              </div>
          </template>

          <!-- IF CART IS EMPTY -->
          <template v-else>
              <p class="p-4">{{$t('marketing_place.cart_is_empty')}}</p>
          </template>
      </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
import { currencyFormatter } from '@/util/Util'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import CartService from '@/services/api/CartService'

export default {
  components: {
    VuePerfectScrollbar
  },
  props: {
    cart_id: {
      default: null,
      type: Number
    },
    cartItems : {type: Array, default: () => [], required: true}
  },
  data () {
    return {
      service: null,
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      }
    }
  },
  computed: {
    cartItemsLength(){
      let total = 0
      this.cartItems.forEach(element => {
        total += element.quantity
      });
      return total
    },
    valueTotal () {
        let total = 0
       this.cartItems.forEach((item) => {
            total += (item.quantity*item.price)
        });
        return total
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    removeItemFromCart (item) {
      this.$vs.loading()
      const cartId = item.cart_id
      const itemId = item.id
      this.service.deleteCartItem(cartId, itemId).then(
        data => {
          this.updateCartHandling(data)
          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
        },
        error => {
          this.showErrors(this.$validator, error)
          this.notifyError(
            this.$vs,
            this.$t('nao-foi-possivel-realizar-esta-operacao')
          )
        }
      ).finally(() => { this.$vs.loading.close() })

    },
    updateCartHandling(item){
      this.$emit('updateCartHandling', item)
    },
    formatValue(value){
      return currencyFormatter(value)
    },
    paymentPurchase(){
      this.$emit('paymentPurchasePopup')
    }
  },
  mounted(){

  },
  beforeMount() {
    this.service = CartService.build(this.$vs)
  },
  beforeDestroy() {

  }
}
</script>

<style lang="scss" scoped>
.con-vs-dropdown--menu {
    z-index: 999;
  }
</style>
